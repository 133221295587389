import React, { useContext, useEffect } from "react"
import { graphql } from 'gatsby'
import SbEditable from 'storyblok-react'

import Seo from "../components/services/seo"
import DynamicComponent from "../components/services/dynamicComponent"
import useStoryblok from "../lib/storyblok"
import { CurrentPageContext } from "../context"

const JournalPressPage = ({ data, location }) => {
  let story = data.storyblokEntry
  story = useStoryblok(story, location)

  const { currentPage, setCurrentPage} = useContext(CurrentPageContext);
  useEffect(() => {
    setCurrentPage([{
      slug: '',
      name: story.name
    }])
  }, [setCurrentPage, story.name])

  const meta_title = 'Interior design press news. Find more news about our company.';
  const meta_description = ' Find the Interior design press news. BlackAndMilk posts about magazines and websites in home decoration area. ';

  const components = story.content.body.map(blok => {
    return (<DynamicComponent blok={blok} data={data} key={blok._uid} />)
  })

  const schema = story.content.schema_markup;

  return (
    <SbEditable content={story.content}>
      <Seo title={meta_title} description={meta_description} schemaMarkup={schema} url={location.href} />
      { components }
    </SbEditable>
)}

export default JournalPressPage

export const query = graphql`
  query JournalPressQuery {
    storyblokEntry(full_slug: {eq: "home-decor-blog"}) {
      content
      name
    }
    journalitems: allStoryblokEntry(
      sort: {fields: created_at}
      filter: {field_component: {eq: "journalpost"}, content: {regex: "/\\\"type\\\":\\\"Press\\\"/"}}
    ) {
      edges {
        node {
          id
          uuid
          name
          slug
          full_slug
          content
          created_at
        }
      }
    }
  }`
